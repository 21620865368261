import * as React from 'react';

export const HappyCustomersSvg = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={80}
			height={80}
			viewBox='0 0 80 80'
			fill='none'
			{...props}
		>
			<path fill='#fff' d='M0 0h80v80H0z' />
			<g clipPath='url(#happy-customers-dweb)'>
				<path
					d='M34.963 71.753c16.945 0 30.68-13.615 30.68-30.41 0-16.794-13.735-30.41-30.68-30.41-16.944 0-30.68 13.616-30.68 30.41 0 16.795 13.736 30.41 30.68 30.41z'
					fill='#FFF162'
				/>
				<path
					d='M30.867 71.568C13.82 69.117 2.003 53.446 4.474 36.565c2.47-16.88 18.292-28.578 35.34-26.128C56.86 12.888 68.678 28.56 66.208 45.44c-2.47 16.88-18.293 28.578-35.34 26.128z'
					fill='#FFD766'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.246 36.688c.901.16 1.502 1.02 1.341 1.92-.22 1.244-.237 3.574.191 6.01.434 2.466 1.268 4.707 2.492 6.02a1.657 1.657 0 11-2.423 2.26c-1.878-2.014-2.859-5.018-3.332-7.706-.478-2.716-.495-5.448-.19-7.163.16-.901 1.02-1.502 1.921-1.342z'
					fill='#FFF8E6'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M40.204 28.451c-.824.348-1.431.89-1.64 1.454a.828.828 0 01-1.554-.574c.43-1.162 1.504-1.965 2.55-2.407 1.038-.437 2.298-.624 3.266-.273 1.122.408 1.979 1.34 2.463 2.375.484 1.034.652 2.288.239 3.405a.828.828 0 11-1.554-.574c.224-.607.158-1.395-.185-2.129-.344-.733-.91-1.295-1.528-1.519m-2.057.242c.833-.351 1.633-.396 2.057-.242zM23.717 21.679c-.72.34-1.27.907-1.5 1.53a.828.828 0 11-1.554-.573c.407-1.102 1.31-1.965 2.346-2.455 1.04-.492 2.3-.65 3.47-.225 1.122.407 1.978 1.34 2.463 2.374.484 1.034.651 2.288.238 3.406a.828.828 0 11-1.554-.574c.225-.608.159-1.395-.185-2.13-.343-.733-.909-1.294-1.528-1.519m-2.196.166c.716-.339 1.515-.413 2.196-.166z'
					fill='#444'
				/>
				<path
					d='M53.862 79.186c11.122-.449 19.77-9.74 19.318-20.753-.453-11.012-9.836-19.575-20.957-19.126-11.122.449-19.77 9.74-19.318 20.753.453 11.013 9.836 19.576 20.957 19.126z'
					fill='#fff'
				/>
				<path
					d='M64.184 57.437c-1.714 3.476-6.233 5.356-10.963 5.547-3.758.152-7.475-.184-10.753-1.452 7.73.492 15.172-.126 21.716-4.095zm-.059-1.417c-2.275-.564-4.309-.67-6.405-.566-5.316.255-10.63 2.038-15.296 4.282 3.644-3.434 9.167-6.05 14.798-6.278 2.685-.108 5.82.887 6.903 2.562zm1.064.701c-.143-3.49-5.58-4.69-8.65-4.566-4.81.194-8.781 2.073-12.158 4.096-.228-.368-.535-.425-1.013-.465a18.722 18.722 0 00-1.685-.022c-.588.024-1.467.1-2.152.236-.331.073-.432.335-.325.728a10.586 10.586 0 001.48 2.91c-.227.903-.102 1.742.687 2.405-.22 1.051-.255 2.195-.172 3.224.078.424.306.534.668.46a12.51 12.51 0 003.767-1.424c.277-.16.402-.333.474-.545l3.374.37c.539.525 1.075.97 1.724 1.46.427.28.88.431 1.427.409 2.44-.118 4.481-.558 6.157-1.262.66-.235 1.12-1.127 1.81-2.377 1.607-.879 4.715-2.534 4.587-5.637z'
					fill='#8000FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M57.534 26.963l-.16.098-.146-.118c-1.901-1.544-4.738-.119-4.636 2.33a2.56 2.56 0 00.658 1.609l3.598 3.982 4.1-3.473c.456-.386.763-.92.868-1.508.43-2.413-2.19-4.2-4.282-2.92zM62.77 37.67l.038.102.11-.003c1.691-.044 2.633 1.916 1.54 3.198a1.776 1.776 0 01-1.037.595l-3.643.656-.746-3.594a1.743 1.743 0 01.17-1.175c.791-1.485 2.977-1.35 3.568.22z'
					fill='#EF4C4C'
				/>
				<path
					d='M27.114 28.396a.398.398 0 01.528-.19l7.376 3.45a.392.392 0 01.188.523 4.08 4.08 0 01-5.405 1.94l-.76-.356a4.013 4.013 0 01-1.927-5.367z'
					fill='#444'
				/>
			</g>
			<defs>
				<clipPath id='happy-customers-dweb'>
					<path
						fill='#fff'
						transform='translate(0 6)'
						d='M0 0h74v74H0z'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
