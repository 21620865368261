import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import LP from 'Components/common/localizedTags/localizedParagraph';

import { CustomerSupportLogoSvg } from 'Assets/svg/trust/CustomerSupportLogoSvg';
import { FeatureMediaSvg } from 'Assets/svg/trust/FeatureMediaSvg';
import { HappyCustomersSvg } from 'Assets/svg/trust/HappyCustomersSvg';
import { TrustpilotLogoSvg } from 'Assets/svg/trust/TrustpilotLogoSvg';

import PlatformUtils from 'Utils/platformUtils';
import { getDomainConfig, getHost } from 'Utils/stateUtils';
import { doesCurrentRouteIncludeString } from 'Utils/urlUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import LH2 from '../common/localizedTags/localizedHeading2';
import LSpan from '../common/localizedTags/localizedSpan';

const StripContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.WHITE};
	padding: 2.5rem 0;
	margin-top: 3.5rem;
	min-width: 70rem;
	max-width: 75rem;
	margin: 3.5rem auto;

	.title {
		${getFontDetailsByLabel(TYPE_LABELS.DISPLAY_SMALL)};
	}

	.content {
		display: flex;
		justify-content: space-between;
		margin: 4rem 0 0;

		.why-block {
			display: flex;
			flex-direction: column;
			width: 17.625rem;

			.block-svg {
				margin-bottom: 0.75rem;
			}

			.block-title {
				margin-bottom: 0.25rem;
				${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)};
			}

			.block-description {
				color: ${colors.GREY_DS.G3};
				display: inline-block;
				word-wrap: break-word;
				white-space: normal;
				${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_MEDIUM)};
			}
		}
	}
`;

type WhyHeadoutStripProps = {
	footerMode?: boolean;
	host?: string;
	location?: any;
	showWhyHeadoutBlock?: boolean;
};

const WhyHeadoutStrip = (props: WhyHeadoutStripProps) => {
	const getSvg = (symbol: any) => {
		switch (symbol) {
			case 'happy-customers':
				return (
					<HappyCustomersSvg
						className='block-svg'
						aria-label='Happy customers icon'
						role='img'
					/>
				);
			case 'trustpilot-logo':
				return (
					<TrustpilotLogoSvg
						className='block-svg'
						aria-label='Trustpilot logo'
						role='img'
					/>
				);
			case 'feature-media':
				return (
					<FeatureMediaSvg
						className='block-svg'
						aria-label='TechCrunch and Apple logo'
						role='img'
					/>
				);
			default:
				return (
					<CustomerSupportLogoSvg
						className='block-svg'
						aria-label='24x7 Help Center icon'
						role='img'
					/>
				);
		}
	};

	const getWhyBlock = (symbol: any, title: any, description: any) => {
		return (
			<div className='why-block'>
				{getSvg(symbol)}
				<LP className='block-title'>{title}</LP>
				<LSpan className='block-description'>{description}</LSpan>
			</div>
		);
	};

	const shouldHideStrip = () => {
		// @ts-expect-error TS(2339): Property 'router' does not exist on type 'WhyHeado... Remove this comment to see the full error message
		const { router: location, host, showWhyHeadoutBlock } = props;

		const isConfirmationPage = doesCurrentRouteIncludeString(
			'confirmation',
			location,
		);

		if (isConfirmationPage) {
			return false;
		}
		return (
			host?.startsWith('book.') ||
			!showWhyHeadoutBlock ||
			doesCurrentRouteIncludeString('svg-tours', location) ||
			doesCurrentRouteIncludeString('seatmap-tours', location) ||
			doesCurrentRouteIncludeString('help', location) ||
			doesCurrentRouteIncludeString('post-covid', location) ||
			doesCurrentRouteIncludeString(
				'global-travel-reopening-tracker',
				location,
			) ||
			doesCurrentRouteIncludeString('supply-partner', location) ||
			doesCurrentRouteIncludeString('bilink-generator', location)
		);
	};

	if (!PlatformUtils.isDesktop() || shouldHideStrip()) {
		return null;
	}

	return (
		<StripContainerStyled>
			<LH2 className='title'>{strings.GWHS_TITLE_DESKTOP}</LH2>
			<div className='content'>
				{getWhyBlock(
					'happy-customers',
					strings.GWHS_ITEMS_DESKTOP[0].title,
					strings.GWHS_ITEMS_MOBILE[0].description,
				)}
				{getWhyBlock(
					'trustpilot-logo',
					'4.4/5',
					strings.GWHS_ITEMS_MOBILE[1].description,
				)}
				{getWhyBlock(
					'feature-media',
					strings.GWHS_ITEMS_DESKTOP[2].title,
					strings.GWHS_ITEMS_DESKTOP[2].description,
				)}
				{getWhyBlock(
					'customer-support-logo',
					strings.GWHS_ITEMS_DESKTOP[3].title,
					strings.GWHS_ITEMS_DESKTOP[3].description,
				)}
			</div>
		</StripContainerStyled>
	);
};

const mapStateToProps = (state: any) => ({
	host: getHost(state),
	showWhyHeadoutBlock: getDomainConfig(state)?.['showWhyHeadoutBlock'],
});

// @ts-expect-error TS(2345): Argument of type 'ConnectedComponent<(props: WhyHe... Remove this comment to see the full error message
export default withRouter(connect(mapStateToProps, null)(WhyHeadoutStrip));
