import React, { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'p'> {
	id?: string;
	className?: string;
	onClick?: (...args: any[]) => any;
	children?: React.ReactNode;
	testId?: string;
}

/**
 * Localized paragraph component
 * Disable google-translate by default
 */
const LP = ({ id, className, onClick, children, testId }: Props) => (
	<p
		{...(testId && { 'data-testid': testId })}
		id={id}
		className={`notranslate ${className || ''}`}
		onClick={onClick}
	>
		{children}
	</p>
);

export default LP;
