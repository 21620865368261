import * as React from 'react';

export const FeatureMediaSvg = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={168}
			height={80}
			fill='none'
			viewBox='0 0 168 80'
			{...props}
		>
			<path fill='#fff' d='M0 0h168v80H0z' />
			<g clipPath='url(#feature-media-dweb)' fill='#0A9E01'>
				<path d='M27.074 34.963v9h-9v18h-9v-18h-9v-9h27zM54.074 34.963h-18v9h18v-9z' />
				<path d='M54.074 52.964v8.999h-27v-18h9v9h18z' />
			</g>
			<g
				clipPath='url(#feature-media-dweb-2)'
				fillRule='evenodd'
				clipRule='evenodd'
				fill='#999'
			>
				<path d='M91.992 30.483C94.621 27.019 98.274 27 98.274 27s.544 3.257-2.064 6.394c-2.788 3.352-5.956 2.802-5.956 2.802s-.599-2.635 1.738-5.713zM90.584 38.477c1.352 0 3.862-1.858 7.128-1.858 5.622 0 7.834 4 7.834 4s-4.326 2.212-4.326 7.579c0 6.053 5.388 8.14 5.388 8.14s-3.766 10.603-8.854 10.603c-2.337 0-4.154-1.577-6.617-1.577-2.509 0-4.999 1.633-6.62 1.633-4.647 0-10.514-10.057-10.514-18.142 0-7.951 4.968-12.128 9.628-12.128 3.027.003 5.379 1.75 6.953 1.75z' />
			</g>
			<defs>
				<clipPath id='feature-media-dweb'>
					<path
						fill='#fff'
						transform='translate(0 35)'
						d='M0 0h54v26.926H0z'
					/>
				</clipPath>
				<clipPath id='feature-media-dweb-2'>
					<path
						fill='#fff'
						transform='translate(74 27)'
						d='M0 0h32.609v40H0z'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
