import * as React from 'react';

export const CustomerSupportLogoSvg = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={80}
			height={80}
			fill='none'
			viewBox='0 0 80 80'
			{...props}
		>
			<path fill='#fff' d='M0 0h80v80H0z' />
			<g clipPath='url(#customer-support-dweb)'>
				<path
					d='M2.536 39.933c-1.4 6.264 1.486 12.29 7.582 13.73 6.096 1.439 11.254-2.69 12.654-8.953 1.4-6.264-1.486-12.291-7.582-13.73-6.097-1.44-11.254 2.69-12.654 8.953z'
					fill='#666'
				/>
				<path
					d='M39.365 80c17.25 0 31.234-14.042 31.234-31.364 0-17.323-13.984-31.365-31.234-31.365S8.131 31.313 8.131 48.635C8.131 65.959 22.115 80 39.365 80z'
					fill='#FFD766'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M25.238 30.986c-.777.447-1.346 1.084-1.502 1.73a.72.72 0 01-1.401-.337c.283-1.174 1.215-2.086 2.184-2.643.959-.551 2.153-.87 3.115-.625 1.118.285 2.035 1.12 2.605 2.092.57.973.855 2.187.582 3.318a.72.72 0 11-1.401-.338c.166-.689.003-1.521-.425-2.251-.429-.731-1.064-1.258-1.717-1.424m-2.04.478c.788-.454 1.585-.594 2.04-.478zM41.365 35.828c-.777.447-1.346 1.085-1.502 1.731a.72.72 0 01-1.401-.338c.283-1.173 1.216-2.085 2.185-2.642.958-.551 2.152-.87 3.114-.625 1.118.284 2.035 1.12 2.605 2.091.571.974.855 2.187.582 3.319a.72.72 0 11-1.4-.338c.165-.69.003-1.521-.425-2.251-.43-.732-1.064-1.258-1.718-1.424m-2.04.477c.789-.453 1.585-.593 2.04-.477z'
					fill='#444'
				/>
				<path
					d='M29.175 37.482c.07-.21.297-.32.506-.246l7.568 2.689a.41.41 0 01.25.514 4.029 4.029 0 01-5.185 2.52l-.576-.204a4.195 4.195 0 01-2.563-5.273z'
					fill='#444'
				/>
				<path
					d='M48.956 53.509c-1.4 6.264 1.487 12.29 7.583 13.73 6.096 1.439 11.253-2.69 12.654-8.953 1.4-6.264-1.487-12.291-7.583-13.73-6.096-1.44-11.253 2.69-12.654 8.953z'
					fill='#9F9F9F'
				/>
				<path
					d='M51.228 53.356c-1.4 6.264 1.487 12.29 7.583 13.73 6.096 1.438 11.253-2.69 12.653-8.954 1.4-6.263-1.486-12.29-7.582-13.73-6.096-1.438-11.253 2.69-12.654 8.954z'
					fill='#666'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.598 30.738c3.768-14.865 17.277-25.44 32.66-24.354 17.402 1.23 30.394 16.89 29.171 34.847-.418 6.14-2.442 11.776-5.62 16.488a1.682 1.682 0 01-2.79-1.88c2.853-4.23 4.677-9.297 5.054-14.836C69.18 24.767 57.447 10.829 42.021 9.74c-13.636-.963-25.763 8.415-29.163 21.825a1.682 1.682 0 01-3.26-.827zM62.566 59.978c-3.197 8.19-11.012 14.01-20.183 14.01-3.683 0-7.155-.942-10.196-2.601l1.382-2.531a18.331 18.331 0 008.814 2.248c7.906 0 14.704-5.018 17.498-12.174l2.685 1.048z'
					fill='#666'
				/>
				<path
					d='M29.007 72.428c-2.399-1.047-3.682-3.494-2.867-5.466.816-1.972 3.421-2.721 5.82-1.674 2.399 1.047 3.682 3.494 2.867 5.466-.816 1.972-3.421 2.722-5.82 1.674z'
					fill='#fff'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14.563 45.765c.785.135 1.312.88 1.177 1.664-.225 1.312-.238 3.725.188 6.237.431 2.54 1.265 4.888 2.517 6.28a1.442 1.442 0 01-2.144 1.928c-1.801-2.003-2.754-5.006-3.215-7.725-.467-2.747-.48-5.499-.187-7.207a1.442 1.442 0 011.664-1.177z'
					fill='#FFF8E6'
				/>
			</g>
			<defs>
				<clipPath id='customer-support-dweb'>
					<path
						fill='#fff'
						transform='translate(0 6)'
						d='M0 0h74v74H0z'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
