import * as React from 'react';

export const TrustpilotLogoSvg = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={80}
			height={80}
			viewBox='0 0 80 80'
			fill='none'
			{...props}
		>
			<path fill='#fff' d='M0 0h80v80H0z' />
			<circle cx={31} cy={43} r={31} fill='#000032' />
			<g clipPath='url(#trustpilot-logo-dweb)'>
				<path
					d='M52.08 38.478l-16.105-.003L31 23.16l-4.976 15.314L9.92 38.47l13.03 9.47-4.976 15.318L31 53.792l13.025 9.466-4.975-15.314 13.029-9.466z'
					fill='#00B67A'
				/>
				<path
					d='M40.179 51.416l-1.128-3.471L31 53.791l9.178-2.376z'
					fill='#005128'
				/>
			</g>
			<defs>
				<clipPath id='trustpilot-logo-dweb'>
					<path
						fill='#fff'
						transform='translate(9.92 23.16)'
						d='M0 0h42.16v40.098H0z'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
